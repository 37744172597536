/* eslint-disable */

import { TransactionInstruction } from "@solana/web3.js";
import * as BufferLayout from "buffer-layout";
import BN from "bn.js";
export class StakingInstruction {
  static createMember(
    nonce,
    registrar_acc,
    member_acc,
    member_pda_acc,
    beneficiary_acc,
    balance_spt_acc,
    balance_vault_acc,
    reward_spt_acc,
    stake_spt_acc,
    token_program_acc,
    programID
  ) {
    console.log(
      "create member",
      "nonce",
      nonce,
      "registrar",
      registrar_acc.toBase58(),
      "member",
      member_acc.toBase58(),
      "member pda",
      member_pda_acc.toBase58(),
      "beneficiary",
      beneficiary_acc.toBase58(),
      "spt",
      balance_spt_acc.toBase58(),
      "vault",
      balance_vault_acc.toBase58(),
      "reward spt",
      reward_spt_acc.toBase58(),
      "stake spt",
      stake_spt_acc.toBase58(),
      "token program",
      token_program_acc.toBase58(),
      "program id",
      programID.toBase58()
    );
    const dataLayout = BufferLayout.struct([
      BufferLayout.u8("i"),
      BufferLayout.blob(1, "nonce"),
    ]);
    const data = Buffer.alloc(dataLayout.span);
    // token list , a buff and loop
    dataLayout.encode(
      { i: 2, nonce: Buffer.from(new BN(nonce).toArray("le", 1)) },
      data
    );
    let keys = [
      { pubkey: registrar_acc, isSigner: false, isWritable: false },
      { pubkey: member_acc, isSigner: false, isWritable: true },
      { pubkey: member_pda_acc, isSigner: false, isWritable: true },
      { pubkey: beneficiary_acc, isSigner: true, isWritable: false },
      { pubkey: balance_spt_acc, isSigner: false, isWritable: false },
      { pubkey: balance_vault_acc, isSigner: false, isWritable: false },
      { pubkey: reward_spt_acc, isSigner: false, isWritable: false },
      { pubkey: stake_spt_acc, isSigner: false, isWritable: false },
      { pubkey: token_program_acc, isSigner: false, isWritable: false },
    ];
    const trxi = new TransactionInstruction({
      keys,
      programId: programID,
      data,
    });
    return trxi;
  }

  static directStake(
    amount,
    registrar_acc,
    registrar_spt_acc,
    registrar_pda_acc,
    pool_mint_acc,
    reward_pool_mint_acc,
    stake_pool_mint_acc,
    member_acc,
    beneficiary_acc,
    from_acc,
    balance_spt_acc,
    balance_vault_acc,
    reward_spt_acc,
    stake_spt_acc,
    clock_acc,
    token_program_acc,
    programID
  ) {
    console.log(
      "direct stake",
      "amount",
      amount,
      "registrar",
      registrar_acc.toBase58(),
      "registrar spt",
      registrar_spt_acc.toBase58(),
      "registrar pda",
      registrar_pda_acc.toBase58(),
      "pool mint",
      pool_mint_acc.toBase58(),
      "reward pool mint",
      reward_pool_mint_acc.toBase58(),
      "stake pool mint",
      stake_pool_mint_acc.toBase58(),
      "member",
      member_acc.toBase58(),
      "beneficiary",
      beneficiary_acc.toBase58(),
      "from",
      from_acc.toBase58(),
      "balance spt",
      balance_spt_acc.toBase58(),
      "balance vault",
      balance_vault_acc.toBase58(),
      "reward spt",
      reward_spt_acc.toBase58(),
      "stake spt",
      stake_spt_acc.toBase58(),
      "clock",
      clock_acc.toBase58(),
      "token program",
      token_program_acc.toBase58(),
      "program id",
      programID.toBase58()
    );
    const dataLayout = BufferLayout.struct([
      BufferLayout.u8("i"),
      BufferLayout.blob(8, "amount"),
    ]);
    const data = Buffer.alloc(dataLayout.span);
    // token list , a buff and loop
    dataLayout.encode(
      { i: 12, amount: Buffer.from(new BN(amount).toArray("le", 8)) },
      data
    );
    let keys = [
      { pubkey: registrar_acc, isSigner: false, isWritable: false },
      { pubkey: registrar_spt_acc, isSigner: false, isWritable: true },
      { pubkey: registrar_pda_acc, isSigner: false, isWritable: false },
      { pubkey: pool_mint_acc, isSigner: false, isWritable: true },
      { pubkey: reward_pool_mint_acc, isSigner: false, isWritable: true },
      { pubkey: stake_pool_mint_acc, isSigner: false, isWritable: true },
      { pubkey: member_acc, isSigner: false, isWritable: true },
      { pubkey: beneficiary_acc, isSigner: true, isWritable: false },
      { pubkey: from_acc, isSigner: false, isWritable: true },
      { pubkey: balance_spt_acc, isSigner: false, isWritable: true },
      { pubkey: balance_vault_acc, isSigner: false, isWritable: true },
      { pubkey: reward_spt_acc, isSigner: false, isWritable: true },
      { pubkey: stake_spt_acc, isSigner: false, isWritable: true },
      { pubkey: clock_acc, isSigner: false, isWritable: false },
      { pubkey: token_program_acc, isSigner: false, isWritable: false },
    ];
    const trxi = new TransactionInstruction({
      keys,
      programId: programID,
      data,
    });
    return trxi;
  }

  static directUnstake(
    amount,
    registrar_acc,
    registrar_pda_acc,
    pool_mint_acc,
    reward_pool_mint_acc,
    stake_pool_mint_acc,
    member_acc,
    member_pda_acc,
    beneficiary_acc,
    to_acc,
    balance_spt_acc,
    balance_vault_acc,
    reward_spt_acc,
    stake_spt_acc,
    clock_acc,
    token_program_acc,
    programID
  ) {
    console.log(
      "direct unstake",
      "amount",
      amount,
      "registrar",
      registrar_acc.toBase58(),
      "registrat pda",
      registrar_pda_acc.toBase58(),
      "pool mint",
      pool_mint_acc.toBase58(),
      "reward pool mint",
      reward_pool_mint_acc.toBase58(),
      "stake pool mint",
      stake_pool_mint_acc.toBase58(),
      "member",
      member_acc.toBase58(),
      "member pda",
      member_pda_acc.toBase58(),
      "beneficiary",
      beneficiary_acc.toBase58(),
      "to",
      to_acc.toBase58(),
      "balance spt",
      balance_spt_acc.toBase58(),
      "balance vault",
      balance_vault_acc.toBase58(),
      "reward spt",
      reward_spt_acc.toBase58(),
      "stake spt",
      stake_spt_acc.toBase58(),
      "clock",
      clock_acc.toBase58(),
      "token program",
      token_program_acc.toBase58(),
      "program id",
      programID.toBase58()
    );
    const dataLayout = BufferLayout.struct([
      BufferLayout.u8("i"),
      BufferLayout.blob(8, "amount"),
    ]);
    const data = Buffer.alloc(dataLayout.span);
    // token list , a buff and loop
    dataLayout.encode(
      { i: 13, amount: Buffer.from(new BN(amount).toArray("le", 8)) },
      data
    );
    let keys = [
      { pubkey: registrar_acc, isSigner: false, isWritable: false },
      { pubkey: registrar_pda_acc, isSigner: false, isWritable: false },
      { pubkey: pool_mint_acc, isSigner: false, isWritable: true },
      { pubkey: reward_pool_mint_acc, isSigner: false, isWritable: true },
      { pubkey: stake_pool_mint_acc, isSigner: false, isWritable: true },
      { pubkey: member_acc, isSigner: false, isWritable: true },
      { pubkey: member_pda_acc, isSigner: false, isWritable: false },
      { pubkey: beneficiary_acc, isSigner: true, isWritable: false },
      { pubkey: to_acc, isSigner: false, isWritable: true },
      { pubkey: balance_spt_acc, isSigner: false, isWritable: true },
      { pubkey: balance_vault_acc, isSigner: false, isWritable: true },
      { pubkey: reward_spt_acc, isSigner: false, isWritable: true },
      { pubkey: stake_spt_acc, isSigner: false, isWritable: true },
      { pubkey: token_program_acc, isSigner: false, isWritable: false },
      { pubkey: clock_acc, isSigner: false, isWritable: false },
    ];
    const trxi = new TransactionInstruction({
      keys,
      programId: programID,
      data,
    });
    return trxi;
  }

  static claimReward(
    registrar_acc,
    reward_pool_mint_acc,
    member_acc,
    beneficiary_acc,
    reward_receiver_acc,
    balance_spt_acc,
    reward_spt_acc,
    vendor_acc,
    vendor_vault_acc,
    vendor_pda_acc,
    token_program_acc,
    clock_acc,
    programID
  ) {
    console.log(
      "claim reward",
      "registrar",
      registrar_acc.toBase58(),
      "reward pool mint",
      reward_pool_mint_acc.toBase58(),
      "member",
      member_acc.toBase58(),
      "beneficiary",
      beneficiary_acc.toBase58(),
      "reward receiver",
      reward_receiver_acc.toBase58(),
      "balance spt",
      balance_spt_acc.toBase58(),
      "reward spt",
      reward_spt_acc.toBase58(),
      "vendor",
      vendor_acc.toBase58(),
      "vendor vualt",
      vendor_vault_acc.toBase58(),
      "vendor pda",
      vendor_pda_acc.toBase58(),
      "token program",
      token_program_acc.toBase58(),
      "clock",
      clock_acc.toBase58(),
      "program id",
      programID.toBase58()
    );
    const dataLayout = BufferLayout.struct([BufferLayout.u8("i")]);
    const data = Buffer.alloc(dataLayout.span);
    // token list , a buff and loop
    dataLayout.encode({ i: 10 }, data);
    let keys = [
      { pubkey: registrar_acc, isSigner: false, isWritable: false },
      { pubkey: reward_pool_mint_acc, isSigner: false, isWritable: true },
      { pubkey: member_acc, isSigner: false, isWritable: true },
      { pubkey: beneficiary_acc, isSigner: true, isWritable: false },
      { pubkey: reward_receiver_acc, isSigner: false, isWritable: true },
      { pubkey: balance_spt_acc, isSigner: false, isWritable: false },
      { pubkey: reward_spt_acc, isSigner: false, isWritable: true },
      { pubkey: vendor_acc, isSigner: false, isWritable: true },
      { pubkey: vendor_vault_acc, isSigner: false, isWritable: true },
      { pubkey: vendor_pda_acc, isSigner: false, isWritable: false },
      { pubkey: token_program_acc, isSigner: false, isWritable: false },
      { pubkey: clock_acc, isSigner: false, isWritable: false },
    ];
    const trxi = new TransactionInstruction({
      keys,
      programId: programID,
      data,
    });
    return trxi;
  }
}
