/* eslint-disable */

"use strict";
const {
  PublicKey,
  SystemProgram,
  Transaction,
  SYSVAR_CLOCK_PUBKEY,
  Keypair,
} = require("@solana/web3.js");
const bs58 = require("bs58");
// const Buffer = require("buffer")
const { TOKEN_PROGRAM_ID, Token, AccountLayout } = require("@solana/spl-token");
import {
  getBalance,
  getBalanceSingle,
  getTokenAccounts,
} from "../getBalance.js";
import { readLittleInt64, readUInt8 } from "../readBinary";
const { StakingInstruction } = require("./instruction.js");
const { timeout, sleep } = require("@/assets/js/timeout");
import { feeDiscount } from "../feeDiscount.js";
import { sendTransaction } from "../sendTransaction";

// state size
const MEMBER_SIZE = 32 * 7 + 8 + 1;

// seed
const seedPre = "0126";
export const rewardSeed = seedPre + "SypStkReward";
export const memberSeed = seedPre + "SypStkMember";
export const rewardVendorSeed = seedPre + "SypStkVendor";

// public key
const programId = "FNjNHqRVNhjghaLjUEsPwuGdf2pyLorAE8YwwgAtbSic";
export const stakingProgramId = programId;
// np mint is general
export const stakingList = [
  {
    registrarKey: "EQMQedaodH8TE9PKYFF3NHFTfjZc9QzLNj4TAVD5sPUq",
    lpMint: "98zVciBJZA6wvMM8b7RghZSSgDXGCkSXEPreTz3QxtAx",
    mpMint: "AyPMGuWw4nt2wJSUa6sFk3cvftbGVy6rFccHRgkttVtx",
    vendorKey: "5DvBghu6gMiBdFTJiyjLfqXDk2VPQRRWnaJGYaBib4CK",
    vendorPDAKey: "FT8JavN13P4rDmDWkQvi14P31JeQWtiz1zv7Lix3WUcV",
    memberSeed: memberSeed,
    lockDay: 0,
    lockDayStr: "No Lockup",
    apy: 0.09,
  },
  {
    registrarKey: "9xJoYgW6cDkzQ38SBK8orGAn28zoknVAA3wdzFztV9Mj",
    lpMint: "CZ6op9z4Xeid8TCbDQ5fmSyTj5M9HtPBJFjBugY8XsH1",
    mpMint: "3vdq6mKD9m8HW8aaUepxnqLvVRsh2QChHUuNY8AYA82g",
    vendorKey: "EWveU6VAhqJEq44HzH8uixXR9Y2LZ7z3nf4pokZiKUso",
    vendorPDAKey: "2rhv3ciaM5gaKgWVWzooE8CJiJgWjETJ4SdfadJHTMvr",
    memberSeed: memberSeed + "30",
    lockDay: 30,
    lockDayStr: "1 Month",
    apy: 0.18,
  },
  {
    registrarKey: "9tyrq3MFHEQYzPXrrVT3nEwnTsdwAe9P8YWFe6De7Zri",
    lpMint: "FmKr3PRDcSH1tkbEoRWxGmqPjpnCF5M6t57AwiGXF7rS",
    mpMint: "9Ycm3goqLZbT1CnPszNtM9DdfnhT8JhqZokSfvghy68o",
    vendorKey: "DzBLHMzC5sU1qHheYbfuvDGMPkym5wGzyz6oBDn3zqVo",
    vendorPDAKey: "CJqUreAf629EaSa5mvHErtLFARfbzkufDhtb7VF3PE44",
    memberSeed: memberSeed + "90",
    lockDay: 90,
    lockDayStr: "3 Months",
    apy: 0.26,
  },
  {
    registrarKey: "6oYAZsgHPDgmzLwp6VguRRbobfaZD7LpMfjL6jd2RmL7",
    lpMint: "6xiJRaiYm8AFRg5bvoQy2AF4LM1X1jQtqxEDZAAYU4jB",
    mpMint: "5mLxak4bqu5NYheebvUgfNroSCRUjr6joe7yKx8WHTDt",
    vendorKey: "9EJYYq4Wi2mUvwWkF3pyekLhvGbokaNb4ziQ13x1WiC6",
    vendorPDAKey: "C2PArC5bD6UDYy1tCtqb2vU9m1rWneR6t61iscrjNjHL",
    memberSeed: memberSeed + "180",
    lockDay: 180,
    lockDayStr: "6 Months",
    apy: 0.4,
  },
  {
    registrarKey: "FnqKfojXKqhLxwAvuwVFPvdqD2rRY3R9UkyXYrjLQN1q",
    lpMint: "AWbsDBHKGmjzJujwrmETG5tBjLf4jGjmopxjWTKc1252",
    mpMint: "2h8oKSoDXZpjLGWqMEX1uhFLWrCd4F79vRWDnnQkqPor",
    vendorKey: "C5CXZmekvQiW8dUaiQTU1C3Mbh3k1fHhW11PtimAX8QG",
    vendorPDAKey: "FcL5ws1rYaRQXVgcweouyXYRVMWGXcQDmK9vBWQLny9s",
    memberSeed: memberSeed + "365",
    lockDay: 365,
    lockDayStr: "1 Year",
    apy: 0.6,
  },
];

// token
const SYPMINT = "FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ";
const USDCMINT = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v";
const sypAcc = new PublicKey(SYPMINT);
export const npMintKey = "6MXp7K7LE83aqTfqfg1tRsX2PdksBQZx6THCXmimEuVY";
const npAcc = new PublicKey(npMintKey);

// account
const programAcc = new PublicKey(programId);
export const nullKey = "11111111111111111111111111111111";

// key is string
// acc is PublicKey

// get staking type
export function getStaking(lockDay) {
  let stakingTypeList = stakingList.map((e) => {
    return e.lockDay;
  });
  let index = stakingTypeList.indexOf(lockDay);
  if (index == -1) {
    console.error("get staking index error");
    return;
  } else {
    return stakingList[index];
  }
}

// get registrar data
export async function getRegistarData(connection, registrarKey) {
  // use account
  let registrarAcc = new PublicKey(registrarKey);
  let registrarData = await connection.getAccountInfo(registrarAcc);
  if (registrarData != null) {
    let accData = registrarData.data;
    let pubLen = 32;
    let authority = bs58.encode(accData.slice(0, pubLen), "hex");
    let nonce = readUInt8(accData.slice(pubLen, pubLen + 1));
    let withdrawalTimeLock = readLittleInt64(
      accData.slice(pubLen + 1, pubLen + 1 + 8)
    );
    let mint = bs58.encode(
      accData.slice(pubLen + 1 + 8, pubLen * 2 + 1 + 8),
      "hex"
    );
    let poolMint = bs58.encode(
      accData.slice(pubLen * 2 + 1 + 8, pubLen * 3 + 1 + 8),
      "hex"
    );
    let rewardMint = bs58.encode(
      accData.slice(pubLen * 3 + 1 + 8, pubLen * 4 + 1 + 8),
      "hex"
    );
    let rewardPoolMint = bs58.encode(
      accData.slice(pubLen * 4 + 1 + 8, pubLen * 5 + 1 + 8),
      "hex"
    );
    let stakePoolMint = bs58.encode(
      accData.slice(pubLen * 5 + 1 + 8, pubLen * 6 + 1 + 8),
      "hex"
    );
    let stakeRate = readLittleInt64(
      accData.slice(pubLen * 6 + 1 + 8, pubLen * 6 + 1 + 8 + 8)
    );
    let stakeSpt = bs58.encode(
      accData.slice(pubLen * 6 + 1 + 8 + 8, pubLen * 6 + 1 + 8 + 8 + 32),
      "hex"
    );
    return {
      authority,
      nonce,
      withdrawalTimeLock,
      mint,
      poolMint,
      rewardMint,
      rewardPoolMint,
      stakePoolMint,
      stakeRate,
      stakeSpt,
    };
  } else {
    console.error("registrar data is null", registrarKey);
    return;
  }
}

// get registrar pda
export async function getRegistrarPDA(registrarKey) {
  if (registrarKey == "") {
    return { registrarPDA: null, nonce: -1 };
  }
  let registrarAcc = new PublicKey(registrarKey);
  let pdaSeed = [registrarAcc.toBuffer()];
  let [registrarPDA, nonce] = await PublicKey.findProgramAddress(
    pdaSeed,
    programAcc
  );
  return { registrarPDA, nonce };
}

// get member key
export async function getMemberKey(wallet, memberSeed) {
  // get member key
  let memberAcc = await PublicKey.createWithSeed(
    wallet.publicKey,
    memberSeed,
    programAcc
  );
  return memberAcc.toBase58();
}

// find an empty member key
export async function getMemberKey2(wallet, memberSeed, connection) {
  var memberData = 1;
  var memberKey;
  var memberSeed2 = memberSeed;
  var i = 0;
  while (memberData) {
    await sleep(3000);
    // get member key
    let memberAcc = await PublicKey.createWithSeed(
      wallet.publicKey,
      memberSeed2,
      programAcc
    );
    memberData = await connection.getAccountInfo(memberAcc);
    if (!memberData) {
      memberKey = memberAcc.toBase58();
    } else {
      memberSeed2 = memberSeed + i.toString();
      i++;
    }
  }
  return { key: memberKey, seed: memberSeed2 };
}

// find all not empty member key
export async function getMemberKey3(wallet, memberSeed, connection) {
  var memberData = 1;
  var membersKey = [];
  var memberSeed2 = memberSeed;
  var i = 0;
  while (memberData) {
    await sleep(3000);
    // get member key
    let memberAcc = await PublicKey.createWithSeed(
      wallet.publicKey,
      memberSeed2,
      programAcc
    );
    memberData = await connection.getAccountInfo(memberAcc);
    if (memberData) {
      membersKey.push(memberAcc.toBase58());
      memberSeed2 = memberSeed + i.toString();
      i++;
    }
  }
  return membersKey;
}

// find all member key
export async function findMemberKey3(connection, wallet, registrarKey) {
  let userWalletAcc = wallet.publicKey;
  let programAcc = new PublicKey(programId);
  let config = {
    commitment: "confirmed",
    filters: [
      { memcmp: { offset: 0, bytes: registrarKey } },
      { memcmp: { offset: 32, bytes: userWalletAcc.toBase58() } },
      { dataSize: MEMBER_SIZE },
    ],
  };
  let list = await connection.getParsedProgramAccounts(programAcc, config);
  return list;
}

// get member pda
export async function getMemberPDA(connection, memberKey) {
  if (memberKey == "") {
    return { memberPDA: null, nonce: -1 };
  }
  let memberData = await getMemberData(connection, memberKey);
  let registrarAcc = new PublicKey(memberData.registrar);
  let memberAcc = new PublicKey(memberKey);
  let pdaSeed = [registrarAcc.toBuffer(), memberAcc.toBuffer()];
  let [memberPDA, nonce] = await PublicKey.findProgramAddress(
    pdaSeed,
    programAcc
  );
  return { memberPDA, nonce };
}

// get member pda
export async function getMemberPDA2(registrarKey, memberKey) {
  if (registrarKey == "" || memberKey == "") {
    return { memberPDA: null, nonce: -1 };
  }
  let registrarAcc = new PublicKey(registrarKey);
  let memberAcc = new PublicKey(memberKey);
  let pdaSeed = [registrarAcc.toBuffer(), memberAcc.toBuffer()];
  let [memberPDA, nonce] = await PublicKey.findProgramAddress(
    pdaSeed,
    programAcc
  );
  return { memberPDA, nonce };
}

// get member data
export async function getMemberData(connection, memberKey) {
  let memberAcc = new PublicKey(memberKey);
  let memberData = await connection.getAccountInfo(memberAcc);
  if (memberData != null) {
    return getMemberDataRaw(memberData);
  } else {
    // console.error('member data is null');
    return;
  }
}

// get member data from binary data
export function getMemberDataRaw(memberData) {
  let accData = memberData.data;
  let pubLen = 32;
  let registrar = bs58.encode(accData.slice(0, pubLen), "hex");
  let beneficiary = bs58.encode(accData.slice(pubLen, pubLen * 2), "hex");
  let meta = bs58.encode(accData.slice(pubLen * 2, pubLen * 3), "hex");
  let spt = bs58.encode(accData.slice(pubLen * 3, pubLen * 4), "hex");
  let vault = bs58.encode(accData.slice(pubLen * 4, pubLen * 5), "hex");
  let rewardSpt = bs58.encode(accData.slice(pubLen * 5, pubLen * 6), "hex");
  let stakeSpt = bs58.encode(accData.slice(pubLen * 6, pubLen * 7), "hex");
  let lastStakeTs = readLittleInt64(accData.slice(pubLen * 7, pubLen * 7 + 8));
  let nonce = readUInt8(accData.slice(pubLen * 7 + 8, pubLen * 7 + 8 + 1));
  return {
    registrar,
    beneficiary,
    meta,
    spt,
    vault,
    rewardSpt,
    stakeSpt,
    lastStakeTs,
    nonce,
  };
}

// create member account
export async function createMemberAcc(
  connection,
  wallet,
  memberKey,
  memberSeed
) {
  // use account
  let walletAcc = wallet.publicKey;
  let memberAcc = new PublicKey(memberKey);
  //create member account
  const balanceNeeded = await connection.getMinimumBalanceForRentExemption(
    MEMBER_SIZE
  );
  const transaction = new Transaction().add(
    SystemProgram.createAccountWithSeed({
      fromPubkey: walletAcc,
      basePubkey: walletAcc,
      seed: memberSeed,
      newAccountPubkey: memberAcc,
      lamports: balanceNeeded,
      space: MEMBER_SIZE,
      programId: programAcc,
    })
  );
  transaction.feePayer = walletAcc;
  let { blockhash } = await connection.getRecentBlockhash();
  transaction.recentBlockhash = blockhash;
  try {
    // sign
    let signed = await wallet.signTransaction(transaction);
    try {
      // send transaction
      let res = await sendTransaction(connection, signed.serialize());
      console.log("create member signature", res.data);
      var memberData;
      let start = new Date();
      while (memberData == null) {
        await sleep(3000);
        memberData = await getMemberData(connection, memberKey);
        if (timeout(start, 180000)) {
          console.log("wait member acc time out");
          return { code: -2, msg: "time out" };
        }
      }
      return { code: 1, msg: "create member acc ok", data: memberData };
    } catch (e) {
      return { code: -3, msg: "create memeber account error", data: e };
    }
  } catch (e) {
    return { code: -1, msg: "sign cancelled", data: e };
  }
}

// get member vaults
export function getMemberVaults(memberData) {
  let vaultsKey = [
    memberData.spt,
    memberData.vault,
    memberData.rewardSpt,
    memberData.stakeSpt,
  ];
  if (
    vaultsKey[0] == nullKey ||
    vaultsKey[1] == nullKey ||
    vaultsKey[2] == nullKey ||
    vaultsKey[3] == nullKey
  ) {
    return { code: 0, msg: "vaults are null", data: vaultsKey };
  }
  return { code: 1, msg: "get vaults ok", data: vaultsKey };
}

export async function createMemberVaults(
  connection,
  wallet,
  registrarKey,
  memberKey
) {
  // use account
  let walletAcc = wallet.publicKey;
  let registrarData = await getRegistarData(connection, registrarKey);
  let lpAcc = new PublicKey(registrarData.poolMint);
  let mpAcc = new PublicKey(registrarData.rewardPoolMint);
  let { memberPDA } = await getMemberPDA2(registrarKey, memberKey);
  const balanceNeeded = await connection.getMinimumBalanceForRentExemption(
    AccountLayout.span
  );
  const transaction = new Transaction();
  let accounts = [];
  let keys = [];
  // create lp spt
  let newAccount = Keypair.generate();
  accounts.push(newAccount);
  keys.push(newAccount.publicKey);
  transaction.add(
    SystemProgram.createAccount({
      fromPubkey: walletAcc,
      newAccountPubkey: newAccount.publicKey,
      lamports: balanceNeeded,
      space: AccountLayout.span,
      programId: TOKEN_PROGRAM_ID,
    })
  );
  transaction.add(
    Token.createInitAccountInstruction(
      TOKEN_PROGRAM_ID,
      lpAcc,
      newAccount.publicKey,
      walletAcc
    )
  );
  // create syp vault
  let newAccount2 = Keypair.generate();
  accounts.push(newAccount2);
  keys.push(newAccount2.publicKey);
  transaction.add(
    SystemProgram.createAccount({
      fromPubkey: walletAcc,
      newAccountPubkey: newAccount2.publicKey,
      lamports: balanceNeeded,
      space: AccountLayout.span,
      programId: TOKEN_PROGRAM_ID,
    })
  );
  transaction.add(
    Token.createInitAccountInstruction(
      TOKEN_PROGRAM_ID,
      sypAcc,
      newAccount2.publicKey,
      memberPDA
    )
  );
  // create reward spt vault
  let newAccount3 = Keypair.generate();
  accounts.push(newAccount3);
  keys.push(newAccount3.publicKey);
  transaction.add(
    SystemProgram.createAccount({
      fromPubkey: walletAcc,
      newAccountPubkey: newAccount3.publicKey,
      lamports: balanceNeeded,
      space: AccountLayout.span,
      programId: TOKEN_PROGRAM_ID,
    })
  );
  transaction.add(
    Token.createInitAccountInstruction(
      TOKEN_PROGRAM_ID,
      mpAcc,
      newAccount3.publicKey,
      walletAcc
    )
  );
  // check if user has stake spt
  let stakeSpt = await getTokenAccounts(connection, walletAcc, npAcc);
  if (!stakeSpt) {
    // create stake spt vault
    let newAccount4 = Keypair.generate();
    accounts.push(newAccount4);
    keys.push(newAccount4.publicKey);
    transaction.add(
      SystemProgram.createAccount({
        fromPubkey: walletAcc,
        newAccountPubkey: newAccount4.publicKey,
        lamports: balanceNeeded,
        space: AccountLayout.span,
        programId: TOKEN_PROGRAM_ID,
      })
    );
    transaction.add(
      Token.createInitAccountInstruction(
        TOKEN_PROGRAM_ID,
        npAcc,
        newAccount4.publicKey,
        walletAcc
      )
    );
  }
  transaction.feePayer = walletAcc;
  // send transaction
  let { blockhash } = await connection.getRecentBlockhash();
  transaction.recentBlockhash = blockhash;
  try {
    // sign
    let signed = await wallet.signTransaction(transaction);
    try {
      for (let i = 0; i < accounts.length; i++) {
        transaction.partialSign(accounts[i]);
      }
      // send transaction
      let res = await sendTransaction(connection, signed.serialize());
      console.log("create member vaults signatrue", res.data);
      let data = accounts.map((e) => {
        return e.publicKey.toBase58();
      });
      if (stakeSpt) {
        data.push(stakeSpt.toBase58());
      }
      for (let i = 0; i < data.length; i++) {
        let start = new Date();
        let acc = new PublicKey(data[i]);
        let accData;
        while (!accData) {
          await sleep(3000);
          accData = await connection.getAccountInfo(acc);
          if (timeout(start, 180000)) {
            console.log("wait member vault acc time out");
            return { code: -2, msg: "time out" };
          }
        }
      }
      return { code: 1, msg: "create member vaults ok", data };
    } catch (e) {
      return { code: -3, msg: "create member vaults error", data: e };
    }
  } catch (e) {
    return { code: -1, msg: "sign cancelled", data: e };
  }
}

// create member account vaults data
export async function createMember(
  connection,
  wallet,
  memberKey,
  vaultsKey,
  registrarKey
) {
  // use account
  let walletAcc = wallet.publicKey;
  let registrarAcc = new PublicKey(registrarKey);
  let memberAcc = new PublicKey(memberKey);
  let { memberPDA, nonce } = await getMemberPDA2(registrarKey, memberKey);
  let sptAcc = new PublicKey(vaultsKey[0]);
  let vaultAcc = new PublicKey(vaultsKey[1]);
  let rewardSptAcc = new PublicKey(vaultsKey[2]);
  let stakeSptAcc = new PublicKey(vaultsKey[3]);
  // make instruction
  let trix = StakingInstruction.createMember(
    nonce,
    registrarAcc,
    memberAcc,
    memberPDA,
    walletAcc, //beneficiary
    sptAcc, //spt
    vaultAcc, // vault
    rewardSptAcc, //reward spt
    stakeSptAcc, //stake spt
    TOKEN_PROGRAM_ID,
    programAcc
  );
  let transaction = new Transaction();
  transaction.add(trix);
  transaction.feePayer = walletAcc;
  let { blockhash } = await connection.getRecentBlockhash();
  transaction.recentBlockhash = blockhash;
  try {
    // sign
    let signed = await wallet.signTransaction(transaction);
    try {
      // send trasaction
      let res = await sendTransaction(connection, signed.serialize());
      let start = new Date();
      var code = 0;
      var memberData;
      while (code != 1) {
        await sleep(3000);
        memberData = await getMemberData(connection, memberKey);
        let temp = getMemberVaults(memberData);
        code = temp.code;
        if (timeout(start, 180000)) {
          return { code: -2, msg: "time out", data: res.data };
        }
      }
      console.log("create member ok", memberData);
      return { code: 1, msg: "create member  ok", data: memberData };
    } catch (e) {
      return { code: -3, msg: "create memeber account error", data: e };
    }
  } catch (e) {
    return { code: -1, msg: "sign cancelled", data: e };
  }
}

// get how much syp coin user have
export async function getBalanceSyp(connection, wallet) {
  // use account
  let walletAcc = wallet.publicKey;
  let balance = await getBalanceSingle(connection, walletAcc, sypAcc);
  return balance;
}

// get how much lp has been stake
export async function getBalanceSpt(connection, memberKey) {
  let memberData = await getMemberData(connection, memberKey);
  if (memberData.spt != nullKey) {
    let sptAcc = new PublicKey(memberData.spt);
    let res = await connection.getTokenAccountBalance(sptAcc);
    if (res != undefined) {
      let balance = {
        acc: memberData.spt,
        amount: parseFloat(res.value.uiAmount),
        decimal: parseFloat(res.value.decimals),
      };
      return balance;
    } else {
      let balance = { acc: memberData.spt, amount: 0, decimal: 9 };
      return balance;
    }
  } else {
    let balance = { acc: memberData.spt, amount: 0, decimal: 9 };
    return balance;
  }
}

// calculate how much reward user could get
export function calculateSptReward(memberData, balanceSpt, stakeRate) {
  let nowTime = new Date().getTime();
  let stakeTime = memberData.lastStakeTs * 1000;
  let d = Math.floor((nowTime - stakeTime) / (1000 * 3600 * 24));
  let rewardAmount = (d * balanceSpt.amount * stakeRate) / 365;
  return rewardAmount;
}

// calculate how much reward user could get in second
export function calculateSptReward2(memberData, balanceSpt, stakeRate) {
  let nowTime = new Date().getTime();
  let stakeTime = memberData.lastStakeTs * 1000;
  let d = nowTime - stakeTime;
  let rewardAmount =
    (d * balanceSpt.amount * stakeRate) / (1000 * 3600 * 24 * 365);
  return rewardAmount;
}

// get how much reward user have
export async function getBalanceRewardSpt(connection, memberKey) {
  let memberData = await getMemberData(connection, memberKey);
  let rewardSptAcc = new PublicKey(memberData.rewardSpt);
  if (memberData.spt != nullKey) {
    let res = await connection.getTokenAccountBalance(rewardSptAcc);
    if (res != undefined) {
      let balance = {
        acc: rewardSptAcc.toBase58(),
        amount: parseFloat(res.value.uiAmount),
        decimal: parseFloat(res.value.decimals),
      };
      return balance;
    }
  } else {
    let balance = { acc: memberData.spt, amount: 0, decimal: 9 };
    return balance;
  }
}

export async function getVendorData(connection, vendorKey) {
  let vendorAcc = new PublicKey(vendorKey);
  let vendorData = await connection.getAccountInfo(vendorAcc);
  if (vendorData != null) {
    let accData = vendorData.data;
    let pubLen = 32;
    let registrar = bs58.encode(accData.slice(0, pubLen), "hex");
    let rewardMint = bs58.encode(accData.slice(pubLen, pubLen * 2), "dec");
    let rewardPoolMint = bs58.encode(
      accData.slice(pubLen * 2, pubLen * 3),
      "hex"
    );
    let vault = bs58.encode(accData.slice(pubLen * 3, pubLen * 4), "hex");
    let manager = bs58.encode(accData.slice(pubLen * 4, pubLen * 5), "hex");
    let nonce = readUInt8(accData.slice(pubLen, pubLen + 1));
    let total = readLittleInt64(
      accData.slice(pubLen * 5 + 1, pubLen * 5 + 1 + 8)
    );
    let lastDropTime = readLittleInt64(
      accData.slice(pubLen * 5 + 1 + 8, pubLen * 5 + 1 + 8 + 8)
    );
    let expiryTime = readLittleInt64(
      accData.slice(pubLen * 5 + 1 + 8 + 8, pubLen * 5 + 1 + 8 + 8 + 32)
    );
    return {
      registrar,
      rewardMint,
      rewardPoolMint,
      vault,
      manager,
      nonce,
      total,
      lastDropTime,
      expiryTime,
    };
  } else {
    console.error("vendor data is null");
    return;
  }
}

// get member pda
export async function getVendorPDA2(registrarKey, vendorKey) {
  if (registrarKey == "" || vendorKey == "") {
    return { vendorPDA: null, nonce: -1 };
  }
  let registrarAcc = new PublicKey(registrarKey);
  let vendorAcc = new PublicKey(vendorKey);
  let pdaSeed = [registrarAcc.toBuffer(), vendorAcc.toBuffer()];
  let [vendorPDA, nonce] = await PublicKey.findProgramAddress(
    pdaSeed,
    programAcc
  );
  return { vendorPDA, nonce };
}

export async function stake(connection, wallet, amount, memberKey) {
  // use account
  let walletAcc = wallet.publicKey;
  let memberData = await getMemberData(connection, memberKey);
  let registrarAcc = new PublicKey(memberData.registrar);
  let registrarData = await getRegistarData(connection, memberData.registrar);
  let { registrarPDA } = await getRegistrarPDA(memberData.registrar);
  let memberAcc = new PublicKey(memberKey);
  // let { memberPDA, nonce } = await getMemberPDA(memberKey);
  let userSypAcc = await getTokenAccounts(connection, walletAcc, sypAcc);
  // make instruction
  let trix = StakingInstruction.directStake(
    amount * 10 ** 9,
    registrarAcc,
    new PublicKey(registrarData.stakeSpt), //registrar stake spt
    registrarPDA,
    new PublicKey(registrarData.poolMint), //pool mint, is spt mint
    new PublicKey(registrarData.rewardPoolMint), //reward pool mint
    new PublicKey(registrarData.stakePoolMint), //stake pool mint
    memberAcc,
    walletAcc, //beneficiary
    userSypAcc, //from
    new PublicKey(memberData.spt), //spt
    new PublicKey(memberData.vault), //vault
    new PublicKey(memberData.rewardSpt), //reward spt
    new PublicKey(memberData.stakeSpt), //stake spt
    SYSVAR_CLOCK_PUBKEY,
    TOKEN_PROGRAM_ID,
    programAcc
  );
  let transaction = new Transaction();
  transaction.add(trix);
  transaction.feePayer = walletAcc;
  let { blockhash } = await connection.getRecentBlockhash();
  transaction.recentBlockhash = blockhash;
  try {
    // sign
    let signed = await wallet.signTransaction(transaction);
    try {
      // send transaction
      let res = await sendTransaction(connection, signed.serialize());
      return res;
    } catch (e) {
      return { code: -3, msg: "stake error", data: e };
    }
  } catch (e) {
    return { code: -1, msg: "sign cancelled", data: e };
  }
}

export async function unstake(connection, wallet, amount, memberKey) {
  // use account
  let walletAcc = wallet.publicKey;
  let memberData = await getMemberData(connection, memberKey);
  let registrarAcc = new PublicKey(memberData.registrar);
  let registrarData = await getRegistarData(connection, memberData.registrar);
  let { registrarPDA } = await getRegistrarPDA(memberData.registrar);
  let memberAcc = new PublicKey(memberKey);
  let { memberPDA } = await getMemberPDA(connection, memberKey);
  let userSypAcc = await getTokenAccounts(connection, walletAcc, sypAcc);
  // make instruction
  let trix = StakingInstruction.directUnstake(
    amount * 10 ** 9,
    registrarAcc,
    registrarPDA,
    new PublicKey(registrarData.poolMint), //pool mint, is spt mint
    new PublicKey(registrarData.rewardPoolMint), //reward pool mint
    new PublicKey(registrarData.stakePoolMint), //stake pool mint
    memberAcc,
    memberPDA,
    walletAcc, //beneficiary
    userSypAcc, //to
    new PublicKey(memberData.spt), //spt
    new PublicKey(memberData.vault), //vault
    new PublicKey(memberData.rewardSpt), //reward spt
    new PublicKey(memberData.stakeSpt), //stake spt
    SYSVAR_CLOCK_PUBKEY,
    TOKEN_PROGRAM_ID,
    programAcc
  );
  let transaction = new Transaction();
  transaction.add(trix);
  transaction.feePayer = walletAcc;
  let { blockhash } = await connection.getRecentBlockhash();
  transaction.recentBlockhash = blockhash;
  try {
    // sign
    let signed = await wallet.signTransaction(transaction);
    try {
      // send transaction
      let res = await sendTransaction(connection, signed.serialize());
      return { code: 1, msg: "unstake ok", data: res.data };
    } catch (e) {
      return { code: -3, msg: "unstake error", data: e };
    }
  } catch (e) {
    return { code: -1, msg: "sign cancelled", data: e };
  }
}

export async function harvest(connection, wallet, memberKey, vendorKey) {
  // use account
  let walletAcc = wallet.publicKey;
  let memberData = await getMemberData(connection, memberKey);
  let registrarAcc = new PublicKey(memberData.registrar);
  let registrarData = await getRegistarData(connection, memberData.registrar);
  let vendorData = await getVendorData(connection, vendorKey);
  let { vendorPDA } = await getVendorPDA2(vendorData.registrar, vendorKey);
  let userSypAcc = await getTokenAccounts(connection, walletAcc, sypAcc);
  // make instruction
  let trix = StakingInstruction.claimReward(
    registrarAcc,
    new PublicKey(registrarData.rewardPoolMint),
    new PublicKey(memberKey),
    walletAcc,
    userSypAcc,
    new PublicKey(memberData.spt),
    new PublicKey(memberData.rewardSpt),
    new PublicKey(vendorKey),
    new PublicKey(vendorData.vault),
    vendorPDA,
    TOKEN_PROGRAM_ID,
    SYSVAR_CLOCK_PUBKEY,
    programAcc
  );
  let transaction = new Transaction();
  transaction.add(trix);
  transaction.feePayer = walletAcc;
  let { blockhash } = await connection.getRecentBlockhash();
  transaction.recentBlockhash = blockhash;
  try {
    // sign
    let signed = await wallet.signTransaction(transaction);
    try {
      // send transaction
      let res = await sendTransaction(connection, signed.serialize());
      return { code: 1, msg: "harvest ok", data: res.data };
    } catch (e) {
      return { code: -3, msg: "harvest error", data: e };
    }
  } catch (e) {
    return { code: -1, msg: "sign cancelled", data: e };
  }
}

export async function withdrawal(
  connection,
  wallet,
  amount,
  memberKey,
  vendorKey
) {
  // use account
  let walletAcc = wallet.publicKey;
  let memberData = await getMemberData(connection, memberKey);
  let registrarAcc = new PublicKey(memberData.registrar);
  let registrarData = await getRegistarData(connection, memberData.registrar);
  let { registrarPDA } = await getRegistrarPDA(memberData.registrar);
  let memberAcc = new PublicKey(memberKey);
  let { memberPDA } = await getMemberPDA(connection, memberKey);
  let vendorData = await getVendorData(connection, vendorKey);
  let { vendorPDA } = await getVendorPDA2(vendorData.registrar, vendorKey);
  let userSypAcc = await getTokenAccounts(connection, walletAcc, sypAcc);
  // make unstake instruction
  let trix = StakingInstruction.directUnstake(
    amount * 10 ** 9,
    registrarAcc,
    registrarPDA,
    new PublicKey(registrarData.poolMint), //pool mint, is spt mint
    new PublicKey(registrarData.rewardPoolMint), //reward pool mint
    new PublicKey(registrarData.stakePoolMint), //stake pool mint
    memberAcc,
    memberPDA,
    walletAcc, //beneficiary
    userSypAcc, //to
    new PublicKey(memberData.spt), //spt
    new PublicKey(memberData.vault), //vault
    new PublicKey(memberData.rewardSpt), //reward spt
    new PublicKey(memberData.stakeSpt), //stake spt
    SYSVAR_CLOCK_PUBKEY,
    TOKEN_PROGRAM_ID,
    programAcc
  );
  // make harvest instruction
  let trix2 = StakingInstruction.claimReward(
    registrarAcc,
    new PublicKey(registrarData.rewardPoolMint),
    new PublicKey(memberKey),
    walletAcc,
    userSypAcc,
    new PublicKey(memberData.spt),
    new PublicKey(memberData.rewardSpt),
    new PublicKey(vendorKey),
    new PublicKey(vendorData.vault),
    vendorPDA,
    TOKEN_PROGRAM_ID,
    SYSVAR_CLOCK_PUBKEY,
    programAcc
  );
  let transaction = new Transaction();
  transaction.add(trix);
  transaction.add(trix2);
  transaction.feePayer = walletAcc;
  let { blockhash } = await connection.getRecentBlockhash();
  transaction.recentBlockhash = blockhash;
  try {
    // sign
    let signed = await wallet.signTransaction(transaction);
    try {
      // send transaction
      let res = await sendTransaction(connection, signed.serialize());
      return { code: 1, msg: "unstake ok", data: res.data };
    } catch (e) {
      return { code: -3, msg: "unstake error", data: e };
    }
  } catch (e) {
    return { code: -1, msg: "sign cancelled", data: e };
  }
}

export async function getTotalStaking(connection) {
  var sptAmount = 0;
  for (let i = 0; i < stakingList.length; i++) {
    let registrarData = await getRegistarData(
      connection,
      stakingList[i].registrarKey
    );
    if (registrarData) {
      if (registrarData.poolMint != nullKey) {
        let res = await connection.getTokenSupply(
          new PublicKey(registrarData.poolMint)
        );
        if (res) {
          sptAmount += res.value.uiAmount;
        } else {
          console.error("get staking spt balance error");
          return 0;
        }
      } else {
        console.error("get staking spt balance error");
        return 0;
      }
    }
  }
  return sptAmount;
}

export async function getUserTotalStaking(connection, wallet) {
  let walletAcc = wallet.publicKey;
  let balance = await getBalance(connection, walletAcc, npAcc);
  let amount = 0.0;
  if (balance) {
    amount = balance.totalBalance;
  }
  return amount;
}

export async function getStakingFeeDiscount(connection, walletAcc) {
  let balance = await getBalanceSingle(connection, walletAcc, npAcc);
  let value = 0.0;
  if (balance) {
    value = balance.amount;
  }
  let temp = feeDiscount.filter((e) => {
    return value >= e.min && value < e.max;
  });
  if (temp.length == 1) {
    return temp[0];
  } else {
    console.log("find fee discount error", temp);
    return;
  }
}

export async function getAllRegistrarData(connection) {
  for (let i = 0; i < stakingList.length; i++) {
    let registrarData = await getRegistarData(
      connection,
      stakingList[i].registrarKey
    );
    if (registrarData) {
      console.log("registrar data", i, registrarData);
    }
  }
}
