export const feeDiscount = [
  { staked: "< 5,000", burn: 0, performance: 0, min: 0, max: 5000 },
  {
    staked: "5,000 - 19,999",
    burn: 0.1,
    performance: 0.025,
    min: 5000,
    max: 19999,
  },
  {
    staked: "20,000 - 39,999",
    burn: 0.2,
    performance: 0.05,
    min: 20000,
    max: 39999,
  },
  {
    staked: "40,000 - 99,999",
    burn: 0.3,
    performance: 0.075,
    min: 40000,
    max: 99999,
  },
  {
    staked: "100,000 - 199,999",
    burn: 0.35,
    performance: 0.095,
    min: 100000,
    max: 199999,
  },
  {
    staked: "200,000 - 499,999",
    burn: 0.4,
    performance: 0.115,
    min: 200000,
    max: 499999,
  },
  {
    staked: "500,000 - 1,999,999",
    burn: 0.45,
    performance: 0.135,
    min: 500000,
    max: 1999999,
  },
  {
    staked: ">= 2,000,000",
    burn: 0.5,
    performance: 0.15,
    min: 2000000,
    max: Infinity,
  },
];
